<template>
  <div class="fullScreen" :class="{ show: showEditDialog }" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after"> 费用审核待办 </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain">
      <el-tabs v-model="approvalType" class="fullScreenMainHeader">
        <el-tab-pane name="UNDETERMINED">
          <span slot="label">审核待办{{ `(${statistics.undetermined})` }}</span>
        </el-tab-pane>
        <el-tab-pane name="NOT_APPLY_CONFIRM">
          <span slot="label">确认待办{{ `(${statistics.notapplyconfirm})` }}</span>
        </el-tab-pane>
        <el-tab-pane name="NOT_PAYED" v-if="permission(['BACKLOG_QRDB'])">
          <span slot="label">支付待办{{ `(${statistics.notpayed})` }}</span>
        </el-tab-pane>
      </el-tabs>
      <div class="fullScreenMainCon">
        <div style="position: relative">
          <div :style="auditBacklogList.length > 0 ? 'opacity:1' : 'opacity:0'">
            <div class="btm-top">
              <!-- 柱形图 -->
              <div id="btm-top-left"></div>
              <!-- 饼图 -->
              <!-- <div id="btm-top-right"></div> -->
            </div>
            <el-table max-height="400" :data="auditBacklogList" border style="width: 100%">
              <el-table-column align="center" prop="name" label="待办模块"> </el-table-column>
              <el-table-column align="center" prop="sumQuantity" label="待办数量">
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button size="medium" @click="routerFn(scope.row)" type="primary">
                    前往
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-if="isShow" style="position: absolute; top: 50%; left: 42%">
            <img src="@/assets/暂无数据.svg" width="100%" alt="暂无数据" />
          </div>
        </div>
      </div>

      <div class="fullScreenOperation" v-if="btnIshwow">
        <el-button type="info" @click="$emit('update:expensebacklogIshwow', false)">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { mapState } from 'vuex'
import { backlogList } from '@/util/regular'
import store from '@/store'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  props: {
    expensebacklogIshwow: {
      type: Boolean,
      default: false,
    },
    btnIshwow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      approvalType: 'UNDETERMINED',
      auditBacklogList: [],
      codeList: [],
      isShow: false,
      loading: false,
      showEditDialog: false,
      statistics: {
        undetermined: 0,
        notapplyconfirm: 0,
        notpayed: 0,
      },
      newArr: [],
    }
  },
  created() {
    this.convertFn()
  },
  mounted() {
    this.statisticsAll()
  },
  computed: {
    ...mapState({
      menus: state => state.user.menus,
      backlogInfo: state => state.user.backlogInfo,
    }),
  },
  watch: {
    approvalType: {
      // immediate: true,
      handler: function (val) {
        this.getData()
      },
    },
    expensebacklogIshwow: {
      immediate: true,
      handler: function (val) {
        this.showEditDialog = val
      },
    },
  },
  methods: {
    // 获取待办数量
    async statisticsAll() {
      this.statistics = {
        undetermined: 0,
        notapplyconfirm: 0,
        notpayed: 0,
      }
      const undetermined = await this.$api.backlog.getActTransferList({
        approvalType: 'UNDETERMINED',
      })
      const notapplyconfirm = await this.$api.backlog.getActTransferList({
        approvalType: 'NOT_APPLY_CONFIRM',
      })
      if (this.permission(['BACKLOG_QRDB'])) {
        const notpayed = await this.$api.backlog.getActTransferList({
          approvalType: 'NOT_PAYED',
        })
        notpayed.data.records.forEach(v => (this.statistics.notpayed += Number(v.sumQuantity)))
      }
      undetermined.data.records.forEach(
        v => (this.statistics.undetermined += Number(v.sumQuantity))
      )
      notapplyconfirm.data.records.forEach(
        v => (this.statistics.notapplyconfirm += Number(v.sumQuantity))
      )
      if (undetermined.data && undetermined.data.records) {
        this.auditBacklogList = []
        this.codeList.forEach(item => {
          undetermined.data.records.forEach(v => {
            if (item.actkey == v.actkey) {
              this.auditBacklogList.push({
                name: v.name,
                sumQuantity: v.sumQuantity,
                passCostPath: item.passCostPath,
                examinePath: item.examinePath,
                passCostPath: item.passCostPath,
                passCosCode: item.passCosCode,
                examinePath: item.examinePath,
                examineCode: item.examineCode,
              })
            }
          })
        })
        const auditNameList = this.auditBacklogList.map(item => item.name)
        const auditSumList = this.auditBacklogList.map(item => item.sumQuantity)
        if (this.auditBacklogList.length == 0) {
          this.isShow = true
        } else {
          this.isShow = false
        }
        this.lzEcharts(auditNameList, auditSumList)
        // this.rzEcharts(auditNameList, auditSumList)
      }
    },
    routerFn(row) {
      if (this.approvalType == 'UNDETERMINED' || this.approvalType == 'NOT_APPLY_CONFIRM') {
        if (row.examinePath) {
          this.$router.push({
            path: row.examinePath,
          })
          this.skipEliminate(row.examineCode)
        } else {
          this.$message({
            showClose: true,
            duration: 5000,
            message: '你目前没有该审批列表权限,请联系管理员配置',
            type: 'warning',
          })
        }
      } else if (this.approvalType == 'NOT_PAYED') {
        if (row.passCostPath) {
          this.$router.push({
            path: row.passCostPath,
          })
          this.skipEliminate(row.passCosCode)
        } else {
          this.$message({
            showClose: true,
            duration: 5000,
            message: '你目前没有该审批列表权限,请联系管理员配置',
            type: 'warning',
          })
        }
      }
    },
    // 获取数据
    async getData() {
      this.loading = true
      this.isShow = false
      const res = await this.$api.backlog.getActTransferList({
        approvalType: this.approvalType,
      })
      if (res.code !== 200) {
        this.loading = false
        return this.$message({
          message: '请求失败',
          type: 'error',
        })
      }
      if (res.data && res.data.records) {
        this.auditBacklogList = []
        this.codeList.forEach(item => {
          res.data.records.forEach(v => {
            if (item.actkey == v.actkey) {
              this.auditBacklogList.push({
                name: v.name,
                sumQuantity: v.sumQuantity,
                passCostPath: item.passCostPath,
                passCosCode: item.passCosCode,
                examinePath: item.examinePath,
                examineCode: item.examineCode,
              })
            }
          })
        })
        const auditNameList = this.auditBacklogList.map(item => item.name)
        const auditSumList = this.auditBacklogList.map(item => item.sumQuantity)
        if (this.auditBacklogList.length == 0) {
          this.isShow = true
        } else {
          this.isShow = false
        }
        this.loading = false
        this.lzEcharts(auditNameList, auditSumList)
        // this.rzEcharts(auditNameList, auditSumList)
      }
    },

    // 柱形图
    lzEcharts(name, sum) {
      let show = []
      sum.forEach((v, i) => {
        show.push({
          name: name[i],
          value: v,
        })
      })
      this.$nextTick(() => {
        var chartDom = document.getElementById('btm-top-left')
        var myChart = echarts.init(chartDom)

        var option
        option = {
          title: {
            top: '10px',
            text: '待办数据',
            left: 'center',
          },
          tooltip: {
            trigger: 'axis',
            formatter: '{b} : {c} 条',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            orient: 'vertical',
            x: 'right', //可设定图例在左、右、居中
            y: 'center', //可设定图例在上、下、居中
          },
          xAxis: [
            {
              type: 'category',
              data: name,
              axisLabel: {
                interval: 0, //代表显示所有x轴标签显示
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
            },
          ],
          series: [
            {
              type: 'bar',
              data: show,
              barWidth: 40, //柱子宽度
              // barGap: 1, //柱子间距
              itemStyle: {
                normal: {
                  color: function (params) {
                    //首先定义一个数组
                    var colorList = [
                      '#EFE42A',
                      '#64BD3D',
                      '#EE9201',
                      '#29AAE3',
                      '#B74AE5',
                      '#0AAF9F',
                      '#E89689',
                      '#8A2BE2',
                      '#FF1493',
                      '#7FFFAA',
                      '#0000FF',
                      '#29D289',
                      '#592D89',
                    ]
                    return colorList[params.dataIndex]
                  },
                  // 以下为是否显示
                  label: {
                    show: true, //开启显示
                    position: 'top', //在上方显示
                    textStyle: {
                      //数值样式
                      color: '#4d4e50',
                      fontSize: 16,
                    },
                  },
                },
              },
            },
          ],
        }
        option && myChart.setOption(option)
      })
    },

    // 饼图
    rzEcharts(name, sum) {
      let show = []
      sum.forEach((v, i) => {
        show.push({
          name: name[i],
          value: v,
        })
      })
      var chartDom = document.getElementById('btm-top-right')
      var myChart = echarts.init(chartDom)

      var option
      option = {
        title: {
          top: '10px',
          text: '待办数据',
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} 人',
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 10,
          top: 10,
          data: name,
        },
        series: [
          {
            type: 'pie',
            radius: '60%',
            center: ['40%', '50%'],
            data: show,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            label: {
              normal: {
                position: 'inner', // 数值显示在内部
                formatter: '{c}', // 格式化数值百分比输出
                show: true, //开启显示
                textStyle: {
                  //数值样式
                  color: '#4d4e50',
                  fontSize: 16,
                },
              },
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  //首先定义一个数组
                  var colorList = [
                    '#EFE42A',
                    '#64BD3D',
                    '#EE9201',
                    '#29AAE3',
                    '#B74AE5',
                    '#0AAF9F',
                    '#E89689',
                    '#8A2BE2',
                    '#FF1493',
                    '#7FFFAA',
                    '#0000FF',
                    '#29D289',
                    '#592D89',
                  ]
                  return colorList[params.dataIndex]
                },
              },
            },
          },
        ],
      }
      option && myChart.setOption(option)
    },
    convertFn() {
      let arr = []
      for (let i = 0; i < this.menus.length; i++) {
        let transformedMenu = this.transformMenu(this.menus[i])
        arr.push(...transformedMenu)
      }
      arr = this.recursionFn(arr)
      // arr = Object.values(arr).flat(Infinity)
      const menuDict = {}
      arr.forEach(menu => {
        if (menu.code) {
          menuDict[menu.code] = menu.href
        }
      })
      this.codeList = [...backlogList]

      this.codeList.forEach(item => {
        if (item.examineCode && menuDict[item.examineCode]) {
          item.examinePath = menuDict[item.examineCode]
        }
        if (item.passCosCode && menuDict[item.passCosCode]) {
          item.passCostPath = menuDict[item.passCosCode]
        }
      })
    },

    transformMenu(menu) {
      let newArr = []
      if (!menu.children || menu.children.length === 0) {
        newArr.push(menu)
      } else if (menu.children && menu.children.length > 0) {
        for (let i = 0; i < menu.children.length; i++) {
          newArr.push(this.transformMenu(menu.children[i]))
        }
      }
      return newArr
    },
    recursionFn(arr) {
      for (let key of arr) {
        if (Array.isArray(key)) {
          this.recursionFn(key)
        } else {
          this.newArr.push(key)
        }
      }
      return this.newArr
    },

    // 跳转后消除红点
    skipEliminate(code) {
      const newArr = this.recursionSkip(code, this.menus)
      store.commit({
        type: 'user/SET_MENUS',
        menus: newArr,
      })
    },
    // 递归
    recursionSkip(code, arr = []) {
      const newArr = []
      arr.forEach(item => {
        if (code === item.code) {
          item.backlog = false
          newArr.push(item)
        } else if (item.children && item.children.length > 0) {
          newArr.push(item)
          this.recursionSkip(code, item.children)
        } else {
          newArr.push(item)
        }
      })
      return newArr
    },
  },
}
</script>

<style lang="scss" scope>
.btm-top {
  display: flex;
  // margin-bottom: 10px;

  #btm-top-left {
    padding-right: 10px;
    height: 400px;
    flex: 1;
  }

  #btm-top-right {
    flex: 1;
    height: 400px;
  }
}
</style>
